import React from "react"
import { Link, graphql } from "gatsby"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./impressum.scss"
import BlockContent from "../components/block-content"

const ImpressumPage = ({ data: { sanityLegalSettings: data } }) => {
  return (
    <Layout page="impressum">
      <SEO title="Impressum" />
      <div className="display-header">
        <h2>Impressum</h2>
      </div>
      <Row>
        <Col xs={12} className="content">
          <BlockContent blocks={data._rawImprint} />
        </Col>
      </Row>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ImprintLegalPageSettingsQuery {
    sanityLegalSettings(_id: { eq: "legalSettings" }) {
      _rawImprint
    }
  }
`

export default ImpressumPage
